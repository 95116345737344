// 引入request
import requests from "../../Utils/request";

//获取客户列表
export const getCustomer = (params) =>
  requests({
    url: `/api/customer/paging/${params}`,
    method: "get",
    // data
  });
  //获取法律顾问套餐
  export const getPerennialLegalAdvisorPackage = () =>
    requests({
      url: `/api/common/PerennialLegalAdvisorPackage/list`,
      method: "get",
      // data
    });
// 添加客户
export const addCustomer = (data) =>
  requests({
    url: `/api/customer/add`,
    method: "post",
    data,
  });
// 查看客户详情
export const getCustomerDetail = (params) =>
  requests({
    url: `/api/customer/detail/${params}`,
    method: "get",
  });
// 编辑客户
export const editCustomer = (data) =>
  requests({
    url: `/api/customer/edit`,
    method: "post",
    data,
  });
// 删除客户联系人
export const delContactCustomer = (data) =>
  requests({
    url: `/api/customer/contact`,
    method: "delete",
    data,
  });
// 删除客户
export const delContact = (data) =>
  requests({
    url: `/api/customer/delete`,
    method: "delete",
    data,
  });
// 创建任务
export const addAssignment = (data) =>
  requests({
    url: `/api/assignment/add`,
    method: "post",
    data,
  });
// 编辑任务
export const editAssignment = (data) =>
  requests({
    url: `/api/assignment/edit`,
    method: "post",
    data,
  });
// 删除任务
export const delAssignment = (data) =>
  requests({
    url: `/api/assignment`,
    method: "delete",
    data,
  });
// 获取任务详情
export const getAssignmentDetail = (id) =>
  requests({
    url: `/api/assignment/detail/${id}`,
    method: "get",
  });
// 获取客户下拉列表
export const getCustomerList = () =>
  requests({
    url: `/api/customer/select/list`,
    method: "get",
  });
// 获取任务列表
export const getTaskList = (params) =>
  requests({
    url: `/api/assignment/paging/${params}`,
    method: "get",
  });
// 导出任务列表
export const getTaskListFile = (params) =>
  requests({
    url: `/api/assignment/export/${params}`,
    method: "get",
    responseType: 'blob',
  });
// 获取未完成任务列表
export const getUnfinshedTaskList = (params) =>
  requests({
    url: `/api/assignment/unfinshed/list${params}`,
    method: "get",
  });
// 添加服务记录
export const addServiceRecord = (data) =>
  requests({
    url: `/api/assignment/ServiceRecord/add`,
    method: "post",
    data,
  });
// 编辑服务记录
export const editServiceRecord = (data) =>
  requests({
    url: `/api/assignment/ServiceRecord/edit`,
    method: "PUT",
    data,
  });
// 获取服务记录列表
export const getServicerecordList = (params) =>
  requests({
    url: `/api/assignment/servicerecord/paging/${params}`,
    method: "get",
  });
// 导出服务列表
export const getServeListFile = (params) =>
  requests({
    url: `/api/assignment/servicerecord/export/${params}`,
    method: "get",
    responseType: 'blob',
  });
// 删除服务记录
export const delServiceRecord = (data) =>
  requests({
    url: `/api/assignment/ServiceRecord`,
    method: "delete",
    data,
  });
// 获取服务记录详情
export const getServicerecordDetail = (id) =>
  requests({
    url: `/api/assignment/servicerecord/detail/${id}`,
    method: "get",
  });
// 新增项目
export const addProject = (data) =>
  requests({
    url: `/api/project/add`,
    method: "post",
    data,
  });
// 编辑项目
export const editProject = (data) =>
  requests({
    url: `/api/project/edit`,
    method: "post",
    data,
  });
  // 删除项目
  export const delProject = (data) =>
    requests({
      url: `/api/project/delete`,
      method: "delete",
      data,
    });
// 获取项目下的任务数量
export const getServicerecordCount = (data) =>
  requests({
    url: `/api/assignment/servicerecord/count`,
    method: "post",
    data,
  });
// 获取项目列表
export const getProjectList = (params) =>
  requests({
    url: `/api/project/paging/${params}`,
    method: "get",
  });
// 获取项目详情  
export const getProjectDetail = (id) =>
  requests({
    url: `/api/project/detail/${id}`,
    method: "get",
  });
// 查询项目任务下的服务记录数量
export const getSCountervicerecord = (data) =>
  requests({
    url: `/api/assignment/servicerecord/count`,
    method: "post",
    data,
  });