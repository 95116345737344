<template>
  <div class="add-project">
    <p class="all-crumbs">项目管理 > 我的项目</p>
    <div class="row-box">
      <p class="label">
        <span>* </span>
        项目名称
      </p>
      <el-input
        type="text"
        v-model="form.name"
        placeholder="请输入项目名称"
      />
    </div>
    <div class="row-box">
      <p class="label">
        <span>* </span>
        项目封面
      </p>
      <el-upload
        class="avatar-uploader"
        :action="objData.host"
        :data="objData"
        :show-file-list="false"
        :on-success="handleAvatarSuccess"
        :before-upload="beforeAvatarUpload"
      >
        <div class="shade" v-if="imageUrl">
          <!-- <div class="img-box"> -->
            <img :src="imageUrl" class="avatar" />
          <!-- </div> -->
          <div>更换</div>
        </div>
        <i v-else class="el-icon-plus avatar-uploader-icon">
          <span>上传封面</span>
        </i>
      </el-upload>
      <span class="span2" @click="setInitImg">使用默认封面</span>
    </div>
    <div class="row-box">
      <p class="label">
        <span>* </span>
        项目描述
      </p>
      <el-input
        type="textarea"
        :autosize="{ minRows: 4 }"
        placeholder="请输入任务内容"
        v-model="form.description"
      >
      </el-input>
    </div>
    <div class="row-box">
      <p class="label">项目任务</p>
      <div class="right-box">
        <div class="add-box" @click="add">
          <span>+</span>
          <p>添加任务</p>
        </div>
        <div class="tasks-box">
          <div
            class="tasks-row"
            v-for="(item, index) in taskList"
            :key="item.id"
          >
            <p class="p-1" :title="item.name">{{ item.name }}</p>
            <p class="p-2" :title="item.customerName">
              {{ item.customerName }}
            </p>
            <p class="p-3" :title="item.typeName">{{ item.typeName }}</p>
            <p class="p-4" :title="item.agent">{{ item.agent }}</p>
            <i class="el-icon-delete-solid" @click="delTasks(index)"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="row-box">
      <p class="label">
        <span>* </span>
        项目日期
      </p>
      <el-date-picker
        v-model="dateList"
        type="daterange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        @change="selectDate">
      </el-date-picker>
    </div>
    <span style="flex: 1"></span>
    <div class="btns-box">
      <button class="btn" @click="cancel">取 消</button>
      <button class="btn2" @click="submit">保 存</button>
    </div>
      <selectTask
        v-if="showDialog"
        :selectList="taskList"
        @setTaskContent="setTaskContent"
      ></selectTask>
  </div>
</template>

<script>
import selectTask from '@/views/Dialog/selectTask'
import { getPolicyToken } from '@/FackApi/api/index.js'
import { addProject, editProject, getProjectDetail } from '@/FackApi/api/project.js'
// import { delOssFile } from "@/Utils/alioss.js";
import { mapGetters } from 'vuex'
var OSS = require('ali-oss')
export default {
  name: 'addProject',
  components: {
    selectTask
  },
  props: {
    info: {
      type: Object
    }
  },
  data () {
    return {
      form: {
        name: '',
        cover: '',
        description: '',
        startTime: '',
        endTime: '',
        projectAssignmentRelations: []
      },
      initImg: 'project/cover/默认封面.png', // 默认oss已存储的封面图路径
      showDialog: false,
      taskList: [],
      objData: {
        OSSAccessKeyId: '',
        policy: '',
        Signature: '',
        key: '',
        host: '*',
        dir: ''
      },
      disabled: false, // 是否禁止输入
      imageUrl: '',
      dateList: [],
      ossInfo: { // oss参数
        expireTime: 120,
        uploadDir: 'project/cover/'
      },
      throttleNum: 0,
      flag: null // 计时器
    }
  },
  computed: {
    ...mapGetters({
      userInfo: 'index/userInfoState',
      ossInfo2: 'index/ossInfoState'
    })
  },
  created () {
    this.init()
  },
  mounted () {},
  methods: {
    init () {
      // console.log(this.userInfo);
      if (this.info.type == 'edit') {
        this.getProjectDetailInfo()
      }
    },
    // 显示图片
    handleAvatarSuccess (res, file) {
      this.imageUrl = URL.createObjectURL(file.raw)
    },
    // 获取项目详情
    async getProjectDetailInfo () {
      const client = new OSS({
        region: 'oss-cn-shenzhen',
        accessKeyId: this.ossInfo2.accessid,
        accessKeySecret: '5ZUDWAJ0Gz4o52kubRVYCaJRRQRims',
        bucket: 'ilaas-files'
      })
      const res = await getProjectDetail(this.info.row.id)
      if (res.code == 0) {
        this.form = {
          name: res.item.name,
          cover: res.item.cover,
          description: res.item.description,
          startTime: res.item.startTime,
          endTime: res.item.endTime,
          projectAssignmentRelations: []
        }
        this.imageUrl = client.signatureUrl(res.item.cover, { expires: 600, process: 'image/resize,w_300' })
        this.dateList = [
          res.item.startTime, res.item.endTime
        ],
        console.log(res.item.assignments)
        res.item.assignments.forEach(item => {
          this.taskList.push(
            {
              id: item.id,
              name: item.name,
              customerName: item.customerName,
              typeName: item.typeName,
              agent: item.agent
            }
          )
          this.form.projectAssignmentRelations.push(
            {
              assignmentId: item.id
            }
          )
        })
      }
    },
    // 默认封面
    setInitImg () {
      const client = new OSS({
        region: 'oss-cn-shenzhen',
        accessKeyId: this.ossInfo2.accessid,
        accessKeySecret: '5ZUDWAJ0Gz4o52kubRVYCaJRRQRims',
        bucket: 'ilaas-files'
      })
      this.imageUrl = client.signatureUrl(this.initImg, { expires: 600, process: 'image/resize,w_300' })
      this.form.cover = this.initImg
    },
    // 上传前的操作
    async beforeAvatarUpload (file) {
      const imgType = ['image/jpeg', 'image/png', 'image/jpg', 'image/webp']
      const isLt5M = file.size / 1024 / 1024 < 5
      if (!imgType.includes(file.type)) {
        this.$message.error('上传封面图片只能是 JPG、PNG、WPS 格式!')
        return
      }
      if (!isLt5M) {
        this.$message.error('上传封面图片大小不能超过 5MB!')
        return
      }
      const res3 = await getPolicyToken(this.ossInfo)
      if (res3.code == 0) {
        const data = JSON.parse(res3.content)
        this.objData = {
          OSSAccessKeyId: data.accessid,
          policy: data.policy,
          signature: data.signature,
          expire: data.expire,
          host: data.host,
          dir: data.dir,
          key: `project/cover/${this.userInfo.userId}/${new Date().getTime()}-${file.name}`
          // key: `project/cover/${file.name}`,
        }
        console.log(this.objData)
        this.form.cover = this.objData.key
      } else {

      }
    },
    // 新增任务
    add () {
      this.showDialog = true
    },
    // 选择任务
    setTaskContent (arr) {
      // console.log(arr);
      this.taskList = []
      this.form.projectAssignmentRelations = []
      arr.forEach((item) => {
        if (item.select) {
          this.taskList.push(item)
          this.form.projectAssignmentRelations.push(
            {
              assignmentId: item.id
            }
          )
        }
      })
      // console.log(this.taskList);
    },
    // 删除任务
    delTasks (idx) {
      this.taskList.splice(idx, 1)
      this.form.projectAssignmentRelations.splice(idx, 1)
    },
    // 选择时间
    selectDate () {
      this.form.startTime = this.$getDate(2, this.dateList[0])
      this.form.endTime = this.$getDate(2, this.dateList[1])
    },
    // 保存
    async submit () {
      if (this.throttleNum != 0) return
      this.throttleNum = 1
      const that = this
      this.flag = setInterval(() => {
        that.throttleNum++
        if (that.throttleNum == 2) {
          that.throttleNum = 0
          clearInterval(this.flag)
        }
      }, 1000)
      const verifyObj = {
        name: '项目名称',
        cover: '项目封面',
        description: '项目描述'
      }
      for (const key in verifyObj) {
        if (!this.form[key]) {
          this.$message.warning(`${verifyObj[key]}不能为空！`)
          return
        }
      }
      // if (this.taskList.length == 0) {
      //   this.$message.warning(`项目任务不能为空！`);
      //   return;
      // }
      if (this.dateList.length == 0) {
        this.$message.warning('请选择项目开始，结束时间！')
        return
      }
      const params = this.form
      if (this.info.type == 'edit') {
        params.id = this.info.row.id
      }
      // console.log(params);return
      let res4 = {}
      if (this.info.type == 'add') {
        res4 = await addProject(params)
      } else {
        res4 = await editProject(params)
      }
      if (res4.code == 0) {
        this.$parent.init()
        this.cancel()
      }
    },
    // 取消
    cancel () {
      this.$parent.showTable = true
    }
  },
  beforeDestroy () {
    clearInterval(this.flag)
  }
}
</script>

<style lang="less" scoped>
.add-project {
  display: flex;
  flex-direction: column;
  // overflow-y: auto;
  // height: 75vh;
  background-color: #fff;
  padding: 30px;
  margin: 20px 15px;
  border-radius: 18px;
  margin-bottom: 30px;
  .all-crumbs {
    margin-bottom: 20px !important;
  }
  /deep/ .row-box {
    width: 50%;
    display: flex;
    // align-items: center;
    line-height: 40px;
    margin-bottom: 14px;
    .label {
      min-width: 72px;
      font-size: 14px;
      color: #909399;
      margin-right: 18px;
      white-space: nowrap;
      span {
        color: red;
      }
    }
    .el-input {
      flex: 1;
      height: 40px;
      font-size: 14px;
      background: #fff;
      border-radius: 10px;
      box-sizing: border-box;
      input {
        height: 100%;
        width: 100%;
        border-radius: 10px;
      }
    }
    .span2 {
      font-size: 12px;
      color: #777;
      margin: 110px 0 0 20px;
      cursor: pointer;
    }
    .right-box {
      width: 100%;
      .add-box {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 140px;
        height: 52px;
        background-color: #f0f3fb;
        border-radius: 8px;
        cursor: pointer;
        span {
          line-height: 30px;
          font-size: 30px;
          color: #7c90c8;
        }
        p {
          font-size: 14px;
          color: #6474c7;
          margin: 6px 0 0 10px;
        }
      }
      .tasks-box {
        .tasks-row {
          display: flex;
          width: 100%;
          height: 40px;
          border-radius: 6px;
          border: 1px solid #d7dbda;
          margin-top: 10px;
          padding: 0 20px;
          p {
            padding: 0 !important;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          // .p-1 {
          //   flex: 1;
          //   overflow: hidden;
          //   white-space: nowrap;
          //   text-overflow: ellipsis;
          // }
          // .p-2,
          // .p-3,
          // .p-4 {
          //   width: 200px;
          //   margin-right: 10px;
          //   overflow: hidden;
          //   white-space: nowrap;
          //   text-overflow: ellipsis;
          // }
          // .p-2,
          // .p-4 {
          //   width: 100px;
          // }
          .p-1,
              .p-3 {
                width: 30%;
              }
              .p-2,
              .p-4 {
                width: 15%;
              }
          .el-icon-delete-solid {
            line-height: 40px;
            margin-left: 10px;
            cursor: pointer;
          }
        }
      }
    }
    .el-date-editor {
      .el-range-separator {
        display: block;
        width: 20px;
      }
    }
    .el-textarea {
      .el-textarea__inner {
        font-size: 14px;
        border-radius: 10px;
      }
    }
    .el-select {
      flex: 1;
      .el-input {
        width: 100%;
        .el-input__inner {
          width: 100%;
        }
      }
    }
    .avatar-uploader .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 10px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
      background-color: #f0f3fb;
    }
    .avatar-uploader .el-upload:hover {
      border-color: #409eff;
    }
    .avatar-uploader-icon {
      position: relative;
      font-size: 28px;
      color: #7c90c8;
      width: 130px;
      height: 130px;
      font-weight: 600;
      line-height: 130px;
      text-align: center;
      span {
        position: absolute;
        bottom: -40px;
        left: 50%;
        transform: translate(-50%, 0);
        font-size: 14px;
        font-weight: 400;
        color: #6474c7;
        margin-top: 10px;
      }
    }

    .shade {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      width: 130px;
      height: 130px;
      // .img-box {
      //   width: 130px;
      //   height: 130px;
      //   display: block;
      //   img {
      //     width: 100%;
      //   }
      // }
      .avatar {
        width: 130px;
        // height: 130px;
        display: block;
        margin-right: 0 !important;
      }
      div {
        position: absolute;
        top: 0;
        left: 0;
        width: 130px;
        height: 130px;
        line-height: 130px;
        color: #fff;
        background-color: rgba(63, 65, 77, 0.5);
        z-index: -1;
      }
    }
    .shade:hover {
      div {
        z-index: 1;
      }
    }
  }
  .btns-box {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    button {
      width: 140px;
      height: 40px;
      font-size: 14px;
      font-weight: 600;
      color: #bbb;
      border-radius: 25px;
      background: #f1f2f1;
      border: none;
      margin-left: 20px;
      cursor: pointer;
    }
    .btn2 {
      color: #fff;
      background: linear-gradient(90deg, #6878c8 0%, #79baf2 100%);
      border: none;
    }
  }
}
</style>
