<template>
  <div class="add-task-dialog">
    <el-dialog
      class="dialog2"
      width="600px"
      title="快速创建项目任务"
      center
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :show-close="false"
      append-to-body
    >
        <div class="content-box">
          <div class="row-box">
            <p class="label">
              <span>* </span>
              任务名称
            </p>
            <el-input
              type="text"
              v-model="taskForm.name.val"
              :disabled="taskForm.name.status"
              placeholder="请输入任务名称"
            />
          </div>
          <div class="row-box">
            <p class="label">
              <span>* </span>
              选择客户
            </p>
            <el-select v-model="taskForm.customerId.val" @change="selectClient" filterable :disabled="taskForm.customerId.status" placeholder="请选择任务客户">
              <el-option
                v-for="item in clientList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div class="row-box">
            <p class="label">
              <span>* </span>
              业务类型
            </p>
            <el-select
              v-model="taskForm.type.val"
              @change="selectType"
              filterable
              :disabled="taskForm.type.status || !taskForm.customerId.val"
              placeholder="请选择服务类型"
            >
              <el-option
                v-for="item in typeList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div class="row-box row-box3" v-if="isTypeInfo.childs.length > 0">
            <p class="label">
              <span>* </span>
              服务类型
            </p>
            <el-select v-model="taskForm.serveType.val" @change="selectServeType" filterable :disabled="taskForm.serveType.status" placeholder="请选择服务类型">
              <el-option
                v-for="item in isTypeInfo.childs"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
            <p class="btn-p" style="cursor:default;color:#666;margin-left: 10px;" v-if="`${isServeTypeInfo.surplusAmount}` != 'null'">
              剩余次数
              <span style="color: #76b0eb;">{{isServeTypeInfo.surplusAmount}}</span>
              次
            </p>
          </div>
          <!-- <div class="row-box row-box2" v-if="form.customerId.val">
            <p class="label">任务客户</p>
            <el-input
              type="text"
              v-model="taskForm.customerId.name"
              disabled
              placeholder="请输入任务名称"
            />
          </div> -->
          <div class="row-box">
            <p class="label">
              <span>* </span>
              任务内容
            </p>
            <el-input
              type="textarea"
              :autosize="{ minRows: 4 }"
              :disabled="taskForm.content.status"
              placeholder="请输入任务内容"
              v-model="taskForm.content.val"
            >
            </el-input>
          </div>
          <div class="row-box">
            <p class="label">
              <span>* </span>
              是否代理
            </p>
            <el-radio-group v-model="radio">
              <el-radio :label="true">是</el-radio>
              <el-radio :label="false">否</el-radio>
            </el-radio-group>
          </div>
          <div class="row-box" v-if="radio == 1">
            <p class="label">
              <span>* </span>
              代理人
            </p>
            <el-input
              type="text"
              v-model="taskForm.agent.val"
              :disabled="taskForm.agent.status"
              placeholder="请输入代理人姓名"
            />
          </div>
        </div>
        <div class="btns-box2">
          <button class="btn" @click="cancel2">取消</button>
          <button class="btn2" @click="addSubmit">保存</button>
        </div>
      </el-dialog>
  </div>
</template>

<script>
import { addAssignment, getCustomerList } from "@/FackApi/api/project.js";
import { getSelectList } from "@/FackApi/api/index.js";
export default {
  name: "addTaskDialog",
  components: {},
  props: ['info'],
  data() {
    return {
      dialogVisible: true,
      taskForm: {
        name: {
          status: false,
          val: "",
        },
        customerId: {
          status: false,
          val: '',
        },
        serveType: {
          status: false,
          val: "",
        },
        type: {
          status: false,
          val: "",
        },
        content: {
          status: false,
          val: "",
        },
        agent: {
          status: false,
          val: "",
        },
      },
      typeList: [],
      clientList: [],
      projectList: [],
      radio: true,
      throttleNum: 0,
      flag: null, // 计时器
      isTypeInfo: {childs: []}, // 选择的业务类型
      isServeTypeInfo: {surplusAmount: null}, // 选择的服务类型
      isClientInfo: {}, // 选择的客户
    };
  },
  created() {
    // this.getSelectListInfo();
    // console.log(this.info,'this.info');
    // if (this.info) {
    //   this.taskForm.customerId.val = this.info.id;
    //   this.taskForm.customerId.name = this.info.name;
    // }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.getCustomerListInfo();
    },
    // 获取客户列表
    async getCustomerListInfo() {
      let res2 = await getCustomerList();
      if (res2.code == 0) {
        console.log(res2,'客户列表');
        this.clientList = res2.items;
      }
    },
    // 选择客户
    selectClient() {
      // console.log(this.clientList);
      // console.log(form.customerId.val);
      this.taskForm.type.val = "";
      this.taskForm.serveType.val = "";
      this.isTypeInfo = {childs: []};
      // this.isClientInfo
      for (let i = 0; i < this.clientList.length; i++) {
        let item = this.clientList[i];
        if (item.id == this.taskForm.customerId.val) {
          this.isClientInfo = item;
          break;
        }
      }
      this.getSelectListInfo();
    },
    // 获取任务类型列表
    async getSelectListInfo() {
      let res = await getSelectList(this.taskForm.customerId.val);
      if (res.code == 0) {
        console.log(res,'任务类型');
        this.typeList = res.items;
        if (this.isClientInfo.type == 1) {
          this.typeList.splice(0,1);
        }
      }
    },
    // 选择业务类型
    selectType() {
      this.taskForm.serveType.val = "";
      this.isTypeInfo = {childs: []};
      for (let i = 0; i < this.typeList.length; i++) {
        let item = this.typeList[i];
        if (item.id == this.taskForm.type.val) {
          this.isTypeInfo = item;
          break;
        }
      }
    },
    // 选择的服务类型 
    selectServeType() {
      this.isServeTypeInfo = {surplusAmount: null};
      for (let i = 0; i < this.isTypeInfo.childs.length; i++) {
        let item = this.isTypeInfo.childs[i];
        if (item.id == this.taskForm.serveType.val) {
          // item.surplusAmount = 3;
          this.isServeTypeInfo = item;
          break;
        }
      }
      // console.log(this.isServeTypeInfo);
    },
    // 关闭快速创建任务弹框
    cancel2() {
      this.$parent.init();
      this.$parent.showTaskDialog = false;
    },
    // 弹框保存
    async addSubmit() {
      if (this.throttleNum != 0) return;
      this.throttleNum = 1;
      let that = this;
      this.flag = setInterval(()=>{
        that.throttleNum ++;
        if (that.throttleNum == 2) {
          that.throttleNum = 0;
          clearInterval(this.flag);
        }
      },1000)
      let verifyObj = {
        name: "任务名称",
        customerId: "客户名称",
        type: "业务类型",
        serveType: "服务类型",
        content: "任务内容",
      };
      for (let key in verifyObj) {
        if (!this.taskForm[key].val) {
          this.$message.warning(`${verifyObj[key]}不能为空！`);
          return;
        }
      }
      if (this.radio && !this.taskForm.agent.val) {
        this.$message.warning(`代理人姓名不能为空！`);
        return;
      }
      let params = {
        name: this.taskForm.name.val,
        businessType: this.taskForm.type.val,
        type: this.taskForm.serveType.val,
        customerId: this.taskForm.customerId.val  || '',
        content: this.taskForm.content.val,
        remark: "",
        isAgent: this.radio,
        agent: this.radio ? this.taskForm.agent.val : '',
      };
      // console.log(params);return
      let res3 = await addAssignment(params);
      if (res3.code == 0) {
        this.cancel2();
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.flag);
  },
};
</script>

<style lang="less" scoped>
  /deep/ .el-dialog__wrapper,.dialog2 {
  .el-dialog {
    border-radius: 20px !important;
    /deep/ .el-dialog__header {
      height: 60px;
      line-height: 60px;
      font-size: 22px;
      font-weight: bold;
      color: #000;
      border-bottom: 1px solid #e7e7e7;
      padding: 0;
      background: #eaf5ff !important;
      span {
        color: #6474c7;
      }
    }
    .content-box {
      min-height: 370px;
      .row-box {
    // width: 50%;
    display: flex;
    // align-items: center;
    margin-bottom: 14px;
    .label {
      min-width: 72px;
      font-size: 14px;
      line-height: 40px;
      color: #909399;
      margin-right: 14px;
      white-space: nowrap;
      span {
        color: red;
      }
    }
    .el-radio-group {
      display: flex;
      align-items: center;
    }
    .el-input {
      flex: 1;
      height: 40px;
      font-size: 14px;
      background: #fff;
      border-radius: 10px;
      box-sizing: border-box;
      input {
        height: 100%;
        width: 100%;
        border-radius: 10px;
      }
    }
    .el-textarea {
      .el-textarea__inner {
        font-size: 14px;
        border-radius: 10px;
      }
    }
    .el-select {
      flex: 1;
      .el-input {
        width: 100%;
        .el-input__inner {
          width: 100%;
        }
      }
    }
    .el-radio-group {
      .el-radio {
        margin-bottom: 0;
        .el-radio__label {
          font-size: 14px;
        }
      }
      .is-checked {
        .is-checked {
          .el-radio__inner {
            border-color: #6474c7;
            background-color: #6474c7;
          }
        }
        .el-radio__label {
          color: #6474c7;
        }
      }
    }
  }
    }
    .btns-box2 {
    display: flex;
    justify-content: flex-end;
    button {
      width: 140px;
      height: 40px;
      font-size: 16px;
      font-weight: 600;
      color: #bbb;
      border-radius: 25px;
      background: #f1f2f1;
      border: none;
      margin-left: 20px;
      cursor: pointer;
    }
    .btn2 {
      color: #fff;
      background: linear-gradient(90deg, #6878c8 0%, #79baf2 100%);
      border: none;
    }
  }
  .row-box3 {
    display: flex;
    align-items: center;
  }
  }
}
</style>
