<template>
  <div :class="showTable ? 'Ui-colors' : ''">
    <div v-if="showTable">
      <div class="tabel-box">
        <p class="all-crumbs">项目管理 > 我的项目</p>
        <div class="p-box">
          <p
            :class="tableVal == item ? 'in-p' : ''"
            @click="checkTable(item)"
            v-for="item in tableList"
            :key="item"
          >
            {{ item }}
          </p>
        </div>
      </div>
      <div class="cards-box" ref="scrollBox">
        <div
          class="card"
          v-for="item2 in cardList"
          :key="item2.id"
          @mouseenter="mouseOver(item2.id)"
          @mouseleave="mouseLeave"
          @click.stop
          @click="edit(item2)"
        >
          <i
            class="el-icon-circle-close del-icon"
            v-if="mouseId == item2.id"
            @click.stop="delProject(item2)"
          ></i>
          <div class="img-box">
            <img :src="setImg(item2.cover)" alt="" />
          </div>
          <div class="right-box">
            <div class="title-box">
              <p :title="item2.name">{{ item2.name }}</p>
              <span
                :style="`color:${item2.color};background:${item2.bgColor}`"
                >{{ item2.statusName }}</span
              >
            </div>
            <p class="count">任务数：{{ item2.assignmentCount }}</p>
            <el-progress
              :percentage="item2.ratio"
              :format="format"
              :color="item2.color"
            ></el-progress>
            <div class="foot-box">
              <p>进度</p>
              <p :style="`color:${item2.color};`">{{ item2.ratio }}%</p>
            </div>
          </div>
        </div>
        <div class="add-box">
          <img src="@/assets/images/xmgl/wdxm-2.png" @click="add" alt="" />
          <p>新建项目</p>
        </div>
      </div>
    </div>
    <addProject v-else :info="editInfo"></addProject>
  </div>
</template>

<script>
import { core } from "../../config/pluginInit";
import addProject from "./UiColors/addProject.vue";
import { getProjectList, delProject, getServicerecordCount } from "@/FackApi/api/project.js";
import { Loading } from "element-ui";
var OSS = require("ali-oss");
import { mapGetters } from "vuex";
export default {
  name: "UiColors",
  components: {
    addProject,
  },
  data() {
    return {
      // tableList: ['全部','待进行','进行中','已完成','已超时'],
      tableList: ["全部", "待进行", "进行中", "已完成"],
      tableVal: "全部",
      showTable: true,
      currentPage: 1,
      interPage: 12,
      dataSize: 0,
      allCardList: [],
      cardList: [],
      editInfo: {
        type: "add",
        row: {},
      },
      queryData: true, // 是否请求
      client: null,
      mouseId: "", // 鼠标移除项目的id
    };
  },
  computed: {
    ...mapGetters({
      ossInfo: "index/ossInfoState",
    }),
  },
  created() {
    console.log(NaN == false);
    this.init();
  },
  mounted() {
    core.index();
    this.$nextTick(() => {
      this.$refs.scrollBox.addEventListener("scroll", this.lazyLoading); // 滚动到底部，再加载的处理事件
    });
  },
  methods: {
    init() {
      this.currentPage = 1;
      this.cardList = [];
      this.allCardList = [];
      this.getTaskListInfo();
    },
    // 获取项目列表
    async getTaskListInfo() {
      let loadingInstance = Loading.service({ fullscreen: true });
      let params = `${this.currentPage}/${this.interPage}`;
      let res3 = await getProjectList(params);
      // console.log(res3);
      loadingInstance.close();
      if (res3.code == 0) {
        let data = res3.items;
        // console.log(data);
        let newDate = new Date().getTime();
        data.forEach((item) => {
          if (item.assignmentFinishedCount == 0 && newDate < new Date(item.startTime).getTime()) {
            item.status2 = 0;
            item.statusName = "待进行";
            item.ratio = 0;
            item.color = "#f4dd83";
            item.bgColor = "#fff6e7";
          } else if (
            item.assignmentFinishedCount < item.assignmentCount ||
            newDate > new Date(item.startTime).getTime()
          ) {
            item.status2 = 1;
            item.statusName = "进行中";
            item.ratio = Math.floor(
              (item.assignmentFinishedCount / item.assignmentCount) * 100
            );
            item.color = "#44cadd";
            item.bgColor = "#e8f8f7";
          } else {
            item.status2 = 2;
            item.statusName = "已完成";
            item.ratio = 100;
            item.color = "#a17bf1";
            item.bgColor = "#eeeafe";
          }
          if (!item.ratio) {
            item.ratio = 0;
          }
        });
        console.log(data);
        this.cardList = this.cardList.concat(data);
        this.allCardList = this.allCardList.concat(data);
        this.dataSize = res3.totalCount;
      }
    },
    // 鼠标移入项目
    mouseOver(id) {
      this.mouseId = id;
    },
    // 鼠标移除项目
    mouseLeave() {
      this.mouseId = "";
    },
    // 删除项目
    delProject(row) {
      // console.log(row);return
      let val = `请确认是否删除项目【 ${row.name} 】？`
      if (row.assignmentCount != 0) {
        val = `项目【 ${row.name} 】下有 ${row.assignmentCount} 条项目任务，是否确认删除？`
      }
      this.$confirm(val)
      .then((_) => {
        this.delProjects(row);
      })
      .catch((_) => {});
    },
    async delProjects(row) {
      let params = {
        ids: [row.id]
      };
      let res2 = await delProject(params);
      if (res2.code == 0) {
        this.init();
      }
    },
    // 滚动条到底部加载
    lazyLoading(e) {
      const scrollTop = e.target.scrollTop; // 滚动条滚动时，距离顶部的距离
      const windowHeight = e.target.clientHeight; // 可视区的高度
      const scrollHeight = e.target.scrollHeight; // 滚动条的总高度
      // 滚动条到底部
      if (scrollTop + windowHeight === scrollHeight) {
        if (scrollTop + windowHeight === scrollHeight) {
          if (this.queryData) {
            this.currentPage++;
            if (this.cardList.length < this.dataSize) {
              this.getTaskListInfo();
            }
          }
        }
      }
    },
    // 切换tab
    checkTable(val) {
      this.tableVal = val;
      this.cardList = [];
      this.allCardList.forEach((item) => {
        if (val == "全部") {
          this.cardList.push(item);
        } else if (val == "待进行" && item.statusName == "待进行") {
          this.cardList.push(item);
        } else if (val == "进行中" && item.statusName == "进行中") {
          this.cardList.push(item);
        } else if (val == "已完成" && item.statusName == "已完成") {
          this.cardList.push(item);
        }
      });
    },
    // 进度条label
    format() {
      return "";
    },
    setImg(path) {
      const client = new OSS({
        region: "oss-cn-shenzhen",
        accessKeyId: this.ossInfo.accessid,
        accessKeySecret: "5ZUDWAJ0Gz4o52kubRVYCaJRRQRims",
        bucket: "ilaas-files",
      });
      let imgUrl = client.signatureUrl(path, {
        expires: 600,
        process: "image/resize,w_300",
      });
      return imgUrl;
    },
    // 创建项目
    add() {
      this.editInfo.type = "add";
      this.showTable = false;
    },
    // 编辑项目
    edit(row) {
      this.editInfo.type = "edit";
      this.editInfo.row = row;
      this.showTable = false;
    },
  },
};
</script>

<style lang="less" scoped>
.Ui-colors {
  position: relative;
  display: flex;
  flex-direction: column;
  // height: 700px;
  // background-color: #fff;
  // padding: 30px;
  margin: 0 15px;
  margin-top: 127px;
  border-radius: 18px;
  margin-bottom: 30px;
  // box-shadow: 0px 0px 10px 0px rgba(152, 172, 190, 0.2);
  .tabel-box {
    position: absolute;
    top: -110px;
    left: 0;
    width: 100%;
    height: 90px;
    background-color: #fff;
    border-radius: 18px;
    padding: 14px 22px 0;
    .p-box {
      display: flex;
      align-items: center;
      // border-top: 1px solid #E7E7E7;
      padding-top: 8px;
      p {
        width: 100px;
        text-align: center;
        font-size: 14px;
        font-weight: bold;
        color: #333;
        border-bottom: 6px solid #fff;
        margin-right: 10px;
        cursor: pointer;
      }
      .in-p {
        color: #837af3;
        border-bottom: 6px solid #eae4ff;
      }
    }
  }

  .cards-box {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    // max-height: 660px;
    overflow-y: auto;
    .card {
      display: flex;
      flex-direction: initial;
      width: 32%;
      height: 170px;
      background-color: #fff;
      border-radius: 16px;
      box-shadow: 0px 0px 10px 0px rgba(152, 172, 190, 0.2);
      padding: 20px;
      margin: 0 1.9% 30px 0;
      cursor: pointer;
      .del-icon {
        position: absolute;
        top: 0;
        right: 0;
        font-size: 26px;
      }
      .img-box {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 130px;
        height: 130px;
        border-radius: 14px;
        overflow: hidden;
        img {
          width: 99%;
          box-shadow: 0px 0px 30px 2px rgba(45,79,209,0.1);
        }
      }
      // img {
      //   width: 130px;
      //   // height: 130px;
      //   border-radius: 14px;
      // }
      .right-box {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;
        margin-left: 20px;
        .title-box {
          display: flex;
          align-items: center;
          p {
            width: 80%;
            font-size: 14px;
            color: #333;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          span {
            display: block;
            width: 80px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            font-size: 14px;
            // color: #e9c97d;
            // background: #fff6e5;
            border-radius: 16px;
            margin-left: 5px;
          }
        }
        .count {
          font-size: 12px;
          color: #a09e9e;
        }
        /deep/ .el-progress {
          .el-progress-bar {
            padding-right: 0;
          }
        }
        .foot-box {
          display: flex;
          align-items: center;
          justify-content: space-between;
          p {
            font-size: 12px;
            color: #a09e9e;
          }
        }
      }
    }
    .add-box {
      display: flex;
      width: 32%;
      height: 170px;
      background-color: #fff;
      border-radius: 18px;
      box-shadow: 0px 0px 10px 0px rgba(152, 172, 190, 0.2);
      padding: 20px;
      margin: 0 1.9% 30px 0;
      img {
        width: 130px;
        height: 130px;
        cursor: pointer;
      }
      p {
        font-size: 16px;
        color: #333;
        margin-left: 20px;
      }
    }
  }
  .cards-box div:nth-child(3n) {
    margin-right: 0;
  }
}
</style>
