<template>
  <div class="select-task">
    <div v-show="!showTaskDialog">
    <el-dialog
      title="添加任务"
      center
      :show-close="false"
      :visible.sync="dialogVisible"
      width="600px"
    >
      <div class="ipt-box">
        <el-input
          placeholder="请输入内容"
          @keyup.enter.native="iptEnter"
          v-model="keyword"
        >
          <i slot="prefix" class="el-input__icon el-icon-search"></i>
        </el-input>
        <el-button @click="addTask">快速创建项目任务</el-button>
      </div>
      <div class="table-box">
        <div class="head-box">
          <p
            :class="tableVal == item ? 'in-p' : ''"
            @click="changTable(item)"
            v-for="item in tableList"
            :key="item"
          >
            {{ item }}
          </p>
        </div>
        <div v-show="tableVal == '已完成'" class="cards-box" ref="cardsBox">
          <div
            class="card"
            v-for="(item2, index2) in tableData1"
            :key="item2.id"
            @click="selectCard(item2,index2)"
          >
            <span :class="item2.select ? 'dot2' : 'dot'"></span>
            <p class="p-1" :title="item2.name">{{ item2.name }}</p>
            <p class="p-2" :title="item2.customerName">
              {{ item2.customerName }}
            </p>
            <p class="p-3" :title="item2.typeName">{{ item2.typeName }}</p>
            <p class="p-4" :title="item2.agent">{{ item2.agent }}</p>
          </div>
        </div>
        <div v-show="tableVal == '未完成'" class="cards-box">
          <div
            class="card"
            v-for="(item3, index3) in tableData2"
            :key="item3.id"
            @click="selectCard(item3,index3)"
          >
            <span :class="item3.select ? 'dot2' : 'dot'"></span>
            <p class="p-1" :title="item3.name">{{ item3.name }}</p>
            <p class="p-2" :title="item3.customerName">
              {{ item3.customerName }}
            </p>
            <p class="p-3" :title="item3.typeName">{{ item3.typeName }}</p>
            <p class="p-4" :title="item3.agent">{{ item3.agent }}</p>
          </div>
        </div>
      </div>
      <div class="btns-box">
        <button class="btn" @click="cancel">取 消</button>
        <button class="btn2" @click="submit">保 存</button>
      </div>
    </el-dialog>
    </div>
    <addTaskDialog v-if="showTaskDialog"></addTaskDialog>
  </div>
</template>

<script>
import addTaskDialog from './addTaskDialog.vue'
import { getTaskList, getUnfinshedTaskList } from "@/FackApi/api/project.js";
import { Loading } from "element-ui";
import { addAssignment } from "@/FackApi/api/project.js";
export default {
  name: "selectTask",
  components: {
    addTaskDialog,
  },
  props: {
    selectList: {
      type: Array,
    },
  },
  data() {
    return {
      dialogVisible: true,
      innerVisible: false,
      form: {
        name: "",
        phone: "",
        job: "",
      },
      input4: "",
      tableList: ["未完成", "已完成"],
      tableVal: "未完成",
      currentPage: 1,
      interPage: 10,
      dataSize: 0,
      keyword: "",
      tableData: [],
      queryData: true, // 是否请求
      showTaskDialog: false,
      tableData1: [], // 已完成
      tableData2: [], // 未完成
    };
  },
  created() {
    this.init();
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.cardsBox.addEventListener("scroll", this.lazyLoading); // 滚动到底部，再加载的处理事件
    });
  },
  destroyed() {
    // this.$refs.cardsBox.removeEventListener('scroll', this.lazyLoading); // 卸载滚动事件
  },
  methods: {
    init() {
      this.currentPage = 1;
      this.dataSize = 0;
      this.tableData = [];
      this.getTaskListInfo();
      this.getUnfinshedTaskList();
    },
    // 获取未完成任务列表
    async getUnfinshedTaskList() {
      let params = "";
      if (this.keyword) {
        params += `?queryString=${this.keyword}`;
      }
      let res3 = await getUnfinshedTaskList(params);
          // console.log(this.selectList);
      if (res3.code == 0) {
        let data = res3.items;
        data.forEach((item) => {
          item.select = false;
          for (let i = 0; i < this.selectList.length; i++) {
            let item2 = this.selectList[i];
            if (item.id == item2.id) {
              item.select = true;
              break;
            }
          }
        });
        this.tableData2 = data;
      }
    },
    // 获取已完成任务列表
    async getTaskListInfo() {
      let loadingInstance = Loading.service({ fullscreen: true });
      this.queryData = false;
      let params = "";
      params += `${this.currentPage}/10?status=2`;
      if (this.keyword) {
        params += `&queryString=${this.keyword}`;
      }
      let res3 = await getTaskList(params);
      loadingInstance.close();
      this.queryData = true;
       if (res3.code == 0) {
        let data = res3.items;
        data.forEach((item) => {
          item.select = false;
          for (let i = 0; i < this.selectList.length; i++) {
            let item2 = this.selectList[i];
            if (item.id == item2.id) {
              item.select = true;
              break;
            }
          }
        });
        this.tableData1 = this.tableData1.concat(data);
        this.dataSize = res3.totalCount;
      }
    },
    // 获取任务列表
    // async getTaskListInfo() {
    //   let loadingInstance = Loading.service({ fullscreen: true });
    //   this.queryData = false;
    //   let params = "";
    //   if (this.tableVal == "已完成") {
    //     params += `${this.currentPage}/5?status=2`;
    //   }
    //   if (this.keyword) {
    //     params += `&queryString=${this.keyword}`;
    //   }
    //   let res3 = {};
    //   if (this.tableVal == "已完成") {
    //     res3 = await getTaskList(params);
    //   } else {
    //     let params2 = `?&queryString=${this.keyword}`;
    //     res3 = await getUnfinshedTaskList(params2);
    //   }
    //   loadingInstance.close();
    //   this.queryData = true;
    //   if (res3.code == 0) {
    //     let data = res3.items;
    //     data.forEach((item) => {
    //       item.select = false;
    //       for (let i = 0; i < this.selectList.length; i++) {
    //         let item2 = this.selectList[i];
    //         if (item.id == item2.id) {
    //           item.select = true;
    //           break;
    //         }
    //       }
    //     });
    //     if (this.tableVal == "已完成") {
    //       this.tableData1 = this.tableData1.concat(data);
    //       this.dataSize = res3.totalCount;
    //     } else {
    //       this.tableData2 = data;
    //     }
    //   }
    // },
    // 选择任务
    selectCard(row,idx) {
      let newObj = null;
      if (this.tableVal == "已完成") {
        newObj = this.tableData1[idx];
        newObj.select = !newObj.select;
        this.$set(this.tableData1, idx, newObj);
      } else {
        newObj = this.tableData2[idx];
        newObj.select = !newObj.select;
        this.$set(this.tableData2, idx, newObj);
      }
    },
    // 滚动加载
    lazyLoading(e) {
      const scrollTop = e.target.scrollTop; // 滚动条滚动时，距离顶部的距离
      const windowHeight = e.target.clientHeight; // 可视区的高度
      const scrollHeight = e.target.scrollHeight; // 滚动条的总高度
      // 滚动条到底部
      if (scrollTop + windowHeight === scrollHeight) {
        // console.log('-----------');
        if (this.queryData) {
          this.currentPage++;
          if (this.tableData.length < this.dataSize) {
            this.getTaskListInfo();
          }
        }
      }
    },
    // 添加任务
    addTask() {
      this.showTaskDialog = true;
    },
    // 回车
    iptEnter() {
      // this.init();
      if (this.tableVal == "已完成") {
        this.currentPage = 1;
        this.dataSize = 0;
        this.tableData1 = [];
        this.getTaskListInfo();
      } else {
        this.getUnfinshedTaskList();
      }
    },
    // 切换tab栏
    changTable(val) {
      this.tableVal = val;
      this.keyword = '';
    },
    // 关闭项目任务弹框
    cancel() {
      this.$parent.showDialog = false;
    },
    // 项目任务弹框确定
    submit() {
      let newArr = [];
      this.tableData2.map(item => {
        if (item.select) {
          newArr.push(item)
        }
      })
      this.tableData1.map(item => {
        if (item.select) {
          newArr.push(item)
        }
      })
      this.$emit("setTaskContent", newArr);
      this.$parent.showDialog = false;
    },
  },
};
</script>

<style lang="less" scoped>
.select-task {
  /deep/ .el-dialog__wrapper {
    .el-dialog {
      border-radius: 20px;
      overflow: hidden;
      .el-dialog__header {
        height: 60px;
        line-height: 60px;
        font-size: 22px;
        font-weight: bold;
        color: #000;
        border-bottom: 1px solid #e7e7e7;
        padding: 0;
        background: #eaf5ff;
        span {
          color: #6474c7;
        }
      }
      .el-dialog__body {
        padding: 20px;
        .ipt-box {
          display: flex;
          width: 70%;
          margin: 0 auto 16px;
          .el-input {
            height: 40px;
            .el-input__inner {
              font-size: 14px;
              height: 40px;
              padding-left: 36px;
            }
            .el-input__prefix {
              i {
                font-size: 20px;
                line-height: 40px;
              }
            }
          }
          .el-button {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 40px;
            border-radius: 20px;
            margin-left: 30px;
          }
        }
        .table-box {
          border: 1px solid #d7dbda;
          border-radius: 8px;
          overflow: hidden;
          .head-box {
            display: flex;
            p {
              width: 50%;
              height: 40px;
              line-height: 40px;
              text-align: center;
              font-size: 14px;
              font-weight: bold;
              color: #909399;
              background-color: #f2f2f2;
              cursor: pointer;
            }
            .in-p {
              color: #fff;
              background: linear-gradient(90deg, #6878c8 0%, #79baf2 100%);
            }
          }
          .cards-box {
            height: 300px;
            border-radius: 0 0 8px 8px;
            overflow-y: auto;
            .card {
              display: flex;
              flex-direction: initial;
              align-items: center;
              // justify-content: center;
              height: 50px;
              margin: 0 16px;
              border-bottom: 1px solid #dee2e6;
              border-radius: 0;
              cursor: pointer;
              .dot {
                width: 14px;
                height: 14px;
                border-radius: 8px;
                border: 1px solid #c0c4cc;
                margin-right: 16px;
              }
              .dot2 {
                width: 14px;
                height: 14px;
                border-radius: 8px;
                border: 4px solid #6f6ec4;
                box-sizing: border-box;
                margin-right: 16px;
              }
              p {
                padding: 0 !important;
                line-height: 14px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
              .p-1,
              .p-3 {
                width: 30%;
              }
              .p-2,
              .p-4 {
                width: 15%;
              }
            }
          }
        }
        .btns-box {
          display: flex;
          justify-content: flex-end;
          margin-top: 30px;
          button {
            width: 110px;
            height: 46px;
            font-size: 16px;
            font-weight: 600;
            color: #bbb;
            background: #f1f2f1;
            border-radius: 23px;
            border: none;
            margin-left: 20px;
            cursor: pointer;
          }
          .btn2 {
            color: #fff;
            background: linear-gradient(90deg, #6878c8 0%, #79baf2 100%);
            border: none;
          }
        }
      }
    }
  }
}
</style>
